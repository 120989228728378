import React from "react"
import styled, { css } from "styled-components"

import { minD, maxD } from "../../../../assets/styles/helpers/devices"
import { ReviewsVerticalContainer } from "./containers"

export const logoStyles = css`
  width: 6.2rem;
  margin-right: 1.5rem;

  height: 2.2rem;
  fill: var(--review-green);

  @media ${maxD.laptop} {
    width: 4.5rem;
    margin-right: 1rem;
  }

  @media ${maxD.mobileM} {
    width: 5rem;
    margin-right: 0.8rem;
  }

  @media ${maxD.mobileS} {
    width: 3.5rem;
  }
`

export const ReviewContainer = styled.a`
  display: flex;
  align-items: center;

  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.7;
  }

  ${ReviewsVerticalContainer} & {
    &:last-child {
      @media ${minD.tabletL} {
        margin-top: calc(var(--section-spacing) * 0.4);
      }
    }
  }
`

const ReviewStars = styled.span`
  display: block;

  width: 6.25rem;
  height: 1rem;
  margin-bottom: 0.5rem;

  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%2363B367" viewBox="0 0 447.17 69.44"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M164.93,26.37a2.12,2.12,0,0,0-1.72-1.45l-22.57-3.28L130.55,1.19a2.14,2.14,0,0,0-3.83,0L116.63,21.64,94.06,24.92a2.12,2.12,0,0,0-1.72,1.45,2.15,2.15,0,0,0,.54,2.19l16.33,15.91L105.36,67A2.14,2.14,0,0,0,106.2,69a2.17,2.17,0,0,0,2.25.16l20.19-10.61L148.82,69.2A2.13,2.13,0,0,0,151.91,67l-3.85-22.48,16.33-15.91A2.15,2.15,0,0,0,164.93,26.37Z"/><path class="cls-1" d="M72.69,26.37A2.12,2.12,0,0,0,71,24.92L48.4,21.64,38.31,1.19a2.13,2.13,0,0,0-3.82,0L24.39,21.64,1.83,24.92A2.13,2.13,0,0,0,.64,28.56L17,44.47,13.12,67a2.12,2.12,0,0,0,2.1,2.49,2.16,2.16,0,0,0,1-.24L36.4,58.59,56.58,69.2A2.13,2.13,0,0,0,59.68,67L55.82,44.47,72.15,28.56A2.15,2.15,0,0,0,72.69,26.37Z"/><path class="cls-1" d="M352.12,26.37a2.14,2.14,0,0,0-1.73-1.45l-22.56-3.28L317.73,1.19a2.13,2.13,0,0,0-3.82,0L303.82,21.64l-22.57,3.28a2.13,2.13,0,0,0-1.18,3.64L296.4,44.47,292.54,67a2.12,2.12,0,0,0,2.1,2.49,2.23,2.23,0,0,0,1-.24l20.18-10.61L336,69.2A2.13,2.13,0,0,0,339.1,67l-3.86-22.48,16.34-15.91A2.15,2.15,0,0,0,352.12,26.37Z"/><path class="cls-1" d="M447.06,26.37a2.11,2.11,0,0,0-1.72-1.45l-22.56-3.28L412.68,1.19a2.13,2.13,0,0,0-3.82,0l-10.1,20.45L376.2,24.92A2.13,2.13,0,0,0,375,28.56l16.32,15.91L387.49,67a2.12,2.12,0,0,0,2.1,2.49,2.23,2.23,0,0,0,1-.24l20.18-10.61L431,69.2a2.13,2.13,0,0,0,3.1-2.25l-3.86-22.48,16.33-15.91A2.11,2.11,0,0,0,447.06,26.37Z"/><path class="cls-1" d="M259.88,26.37a2.12,2.12,0,0,0-1.72-1.45l-22.57-3.28L225.5,1.19a2.14,2.14,0,0,0-3.83,0L211.58,21.64,189,24.92a2.12,2.12,0,0,0-1.72,1.45,2.15,2.15,0,0,0,.54,2.19l16.33,15.91L200.3,67a2.17,2.17,0,0,0,.85,2.09,2.12,2.12,0,0,0,1.26.4,2.16,2.16,0,0,0,1-.24l20.18-10.61L243.77,69.2A2.13,2.13,0,0,0,246.86,67L243,44.47l16.33-15.91A2.15,2.15,0,0,0,259.88,26.37Z"/></g></g></svg>')
    no-repeat;
  background-size: contain;

  @media ${maxD.laptop} {
    width: 5rem;
    margin-bottom: 0.3rem;
  }

  @media ${maxD.mobileM} {
    height: 0.7rem;
    margin-bottom: 0;
  }

  @media ${maxD.mobileS} {
    width: 3.5rem;
    height: 0.6rem;
  }
`

const ReviewText = styled.p`
  display: inline-block;
  position: relative;
  color: var(--review-green);
  font-size: 0.85rem;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: var(--review-green);
  }

  @media ${maxD.mobileM} {
    font-size: 0.8rem;
  }

  @media ${maxD.mobileS} {
    font-size: 0.7rem;
  }
`

const Review = ({ children, label, href }) => (
  <ReviewContainer href={href} target="_blank">
    {children}
    <div>
      <ReviewStars aria-label="5/5 stars"></ReviewStars>
      <ReviewText>{label}</ReviewText>
    </div>
  </ReviewContainer>
)

export default Review
