import styled from "styled-components"

import { minD, maxD } from "../../../../assets/styles/helpers/devices"

const ReviewsContainer = styled.section`
  display: flex;
`

export const ReviewsHorizontalContainer = styled(ReviewsContainer)`
  width: 100%;
  --margin-top: 70px;
  margin-top: var(--margin-top);

  @media ${minD.mobileL} {
    column-gap: 50px;
  }

  @media ${maxD.laptop} {
    margin-top: calc(var(--margin-top) * 0.7);
  }

  @media ${maxD.mobileL} {
    margin-top: calc(var(--margin-top) * 0.5);
  }
`

export const ReviewsVerticalContainer = styled(ReviewsContainer)`
  z-index: -1;

  @media ${minD.tabletL} {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    width: var(--green-box-width);
  }
`
