import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import ClutchReview from "./atoms/reviews/Clutch"
import GoogleReview from "./atoms/reviews/Google"

import {
  ReviewsHorizontalContainer,
  ReviewsVerticalContainer,
} from "./atoms/reviews/containers"

import SiteLanguage from "../../helpers/siteLanguage"

const ACFQuery = graphql`
  {
    wp {
      reviews {
        en {
          starReviews {
            starReview1
            starReview2
          }
        }
        pl {
          starReviewsPl {
            starReview1Pl
            starReview2Pl
          }
        }
      }
    }
  }
`

const Reviews = ({ style, vertical }) => {
  const acfData = useStaticQuery(ACFQuery)
  const enData = acfData.wp.reviews.en
  const plData = acfData.wp.reviews.pl

  let reviews

  if (SiteLanguage() === "en") {
    reviews = {
      clutch: enData.starReviews?.starReview1,
      google: enData.starReviews?.starReview2,
    }
  } else {
    reviews = {
      clutch: plData.starReviewsPl?.starReview1Pl,
      google: plData.starReviewsPl?.starReview2Pl,
    }
  }

  return (
    <>
      {vertical ? (
        <ReviewsVerticalContainer style={style}>
          <ClutchReview label={reviews.clutch} />
          <GoogleReview label={reviews.google} />
        </ReviewsVerticalContainer>
      ) : (
        <ReviewsHorizontalContainer style={style}>
          <ClutchReview label={reviews.clutch} />
          <GoogleReview label={reviews.google} />
        </ReviewsHorizontalContainer>
      )}
    </>
  )
}

export { Reviews }
