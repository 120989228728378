import React from "react"
import styled from "styled-components"

import { logoStyles } from "./Review"
import ClutchIcon from "../../../../assets/images/svg/clutch-full.svg"

import Review from "./Review"

const StyledClutchIcon = styled(ClutchIcon)`
  ${logoStyles}
`

const ClutchReview = ({ label }) => (
  <Review label={label} href="https://clutch.co/profile/brave-new#highlights">
    <StyledClutchIcon />
  </Review>
)

export default ClutchReview
