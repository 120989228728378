import React from "react"
import styled from "styled-components"

import { logoStyles } from "./Review"
import GoogleIcon from "../../../../assets/images/svg/google.svg"

import Review from "./Review"

const StyledGoogleIcon = styled(GoogleIcon)`
  ${logoStyles}
`

const GoogleReview = ({ label }) => (
  <Review
    label={label}
    href="https://www.google.com/search?q=Brave New#lrd=0x47165b9ec00b24b1:0x1c154b6cd69c600a,1,,,"
  >
    <StyledGoogleIcon />
  </Review>
)

export default GoogleReview
